<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - IP SLA Averages</h3>
		</div>

		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<b-table
					hover
					striped
					:items="data"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<template #cell(rtt)="data">{{ data.item.rtt }}ms</template>
					<template #cell(jitter)="data">
						<span v-if="data.item.jitter">{{ data.item.jitter }}ms</span>
						<span v-else>N/A</span>
					</template>
					<template #cell(pktloss)="data">
						<span v-if="data.item.pktloss">{{ data.item.pktloss }}%</span>
						<span v-else>N/A</span>
					</template>
				</b-table>
			</ITCSpinner>
		</div>
		<div class="footer" v-if="data.length > 0 && data.length > perPage">
			<b-pagination class="pagination" v-model="currentPage" :total-rows="data.length" :per-page="perPage" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'SolarwindsIPSLAAverages',
	props: ['name', 'id'],
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			data: [],
			currentPage: 1,
			perPage: 6,
			sortBy: 'month',
			sortDesc: true,
			fields: [{ key: 'month', sortable: true }, { key: 'rtt', label: 'Latency' }, { key: 'jitter' }, { key: 'pktloss', label: 'Packet Loss' }],
		};
	},
	created() {
		this.loaded = false;
		this.$http.get(`swipslaaverages/${this.id}`).then(resp => {
			this.data = resp.data.data;
			this.loaded = true;
		});
	},
};
</script>

<style>
.footer {
	padding: 0px 30px 0px;
}
.pagination {
	float: right;
}
</style>
